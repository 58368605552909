body,
select,
input {
  font-family: 'Poppins', sans-serif !important;
}

@import 'base/reset';
@import 'base/variable';
@import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import 'base/responsive';
@import 'dashboard/exposure';

.menu-item-active {
  background: $dark-gray2 !important;
}

.table-primary-light {
  thead {
    tr {
      background-color: #edf7ff;
      th {
        vertical-align: middle;
      }
    }
  }
}

span.up-green {
  color: $green4;
}
span.down-red {
  color: $red4;
}

.asset-chart-label {
  display: flex;
  margin-bottom: 0;
  padding-left: 2rem;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15rem);

  @include xl-screen {
    width: 100%;
    padding-bottom: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    @include xl-screen {
      justify-content: flex-start;
    }

    span {
      width: 1rem;
      height: 1rem;
      display: block;
      margin-right: 0.6rem;
      border-radius: 100%;
      background-color: #f00;
    }

    h4 {
      width: 100%;
      max-width: 14rem;
      font-weight: 400;
      font-size: 1.1rem;
      color: rgba(#373959, 0.7);
    }

    p {
      width: 100%;
      max-width: 5rem;
      font-size: 1.2rem;
      color: rgba(#373959, 0.9);
    }
  }
}

.react-datepicker-wrapper {
  user-select: text;
  //margin-left: 1rem;
  z-index: 9999 !important;

  .react-datepicker__input-container {
    height: 100%;

    input {
      /* box-sizing: border-box; */
      /* border-right: inset 10px transparent; */
      height: 100%;
      /* border: none; */
      width: 8.5rem;
      cursor: pointer;
      padding: 0.28rem;
      /* color: transparent; */
      border-radius: 0.2rem;
      background-size: 1rem;
      /* background-color: #f4f8fb; */
      background-repeat: no-repeat;
      background-position: calc(100% - 0.4rem);
    }
  }
}

.react-datepicker {
  z-index: 9999 !important;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    padding: 0.4rem;
  }

  .react-datepicker__current-month {
    margin-bottom: 0.6rem;
  }

  .react-datepicker__day--outside-month {
    color: #bdbdbd;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: #000;
  }
}

.react-datepicker {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
}

.react-datepicker__header {
  background: transparent;
  border: none;
}

.z4 {
  z-index: 4;
}

.text-start {
  text-align: left !important;
}
// DatePicker CSS

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #4b85cd;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4b85cd;
  border-radius: 0.2rem;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 18px;
  border-color: #000;
  border: 1.6px 1.6px 0 0;
}

table tr td {
  vertical-align: middle !important;
}

.bp3-select-popover .bp3-menu {
  max-height: 50vh !important;
  overflow: auto;
}

table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15) !important;
}
table.bp3-html-table.bp3-html-table-bordered tbody tr td.noTopBorder {
  box-shadow: inset 0 0px 0 0 rgba(16, 22, 26, 0.15) !important;
  padding-top: 0px !important;
}

table.bp3-html-table.bp3-html-table-bordered thead tr th {
  box-shadow: none !important;
}
table.bp3-html-table thead {
  background-color: $light-blue-translucent !important;
  font-weight: 500;
}

.bp3-button-text {
  font-family: $pt-font-family;
}

.bp3-card {
  border-radius: 0.6rem;
}

.card-tabs-navbar {
  // background-color: $light-blue-translucent;
  border-radius: 0.6rem 0.6rem 0 0 !important;
  .bp3-tabs {
    height: 100%;
    .bp3-tab-list {
      height: 100%;
    }
  }
}

.tabs-navbar {
  // background-color: $light-blue-translucent;
  .bp3-tabs {
    height: 100%;
    .bp3-tab-list {
      height: 100%;
    }
  }
}

th[colspan='0'] {
  display: none !important;
}

.totalCashData {
  padding: 25px;
  max-width: 85%;
  margin: 0 auto;

  .cashSum-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(129, 150, 180, 0.3);

    .cashSum {
      flex-basis: 25%;
      max-width: 25%;
      font-size: 16px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      text-align: center;
      border-right: 1px solid rgba(129, 150, 180, 0.3);

      label {
        color: #373959;
        margin: 0;
        margin-right: 20px;
      }
      span {
        color: #4b85cd;
      }
    }
  }
}

tr.loading {
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 1rem, #f3f3f3 1rem, #f3f3f3 2rem);
  background-size: 200% 200%;
  animation: barberpole 30s linear infinite;
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

.text-danger {
  color: $red4;
}

.bg-white {
  background-color: white !important;
}

.bg-red-light {
  background-color: #ffd0d0 !important;
}

.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 6px;
  background-color: #b3e5fc;
  margin: 0px auto;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4b85cd;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #edf7ff;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.bp3-heading {
  font-weight: 400;
}

.tree__nested-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tree__item {
  width: 200px;
  height: 30px;
  line-height: 30px;
  border: 2px solid #dedede;
  background-color: #f7f8fa;
  border-radius: 7px;
  padding: 10px 25px;
  margin: 10px;
}

.tree__item--nested {
  position: relative;
}

/* line */
.tree__item--nested:before {
  content: '';
  position: absolute;
  bottom: 50%;
  /* padding of container - 10px */
  left: -60px;
  /* padding of container - 10px */
  width: 60px;
  /* 3 * half height + half of border radius */
  height: calc(150% + 7.5px);
  border-bottom: inherit;
  border-left: inherit;
  border-bottom-left-radius: 15px;
  /* don't overlap items */
  z-index: -1;
}

/* arrow */
.tree__item--nested:after {
  content: '';
  position: absolute;
  /* border-width - 1px */
  left: -8px;
  bottom: calc(50% - 6px);
  border-color: transparent;
  border-left-color: inherit;
  border-style: inherit;
  border-width: 7px;
}

.zMax {
  z-index: 999999;
}
