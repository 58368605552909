@import '../../assets/scss/base/variable';
.AdministrationPageMenu {
  .bp3-active {
    background-color: $light-blue-translucent !important;
    border-left: 5px solid $primary;
  }

  .bp3-menu-item {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    padding-right: 0 !important;
  }

  li:first-child .bp3-menu-item {
    border-radius: 0 0.6rem 0 0;
  }
  li:last-child .bp3-menu-item {
    border-radius: 0 0 0.6rem 0;
  }

  .bp3-menu {
    border-radius: 0 0.6rem 0.6rem 0 !important;
  }

  .bp3-card {
    border-radius: 0 0.6rem 0.6rem 0 !important;
  }
}
