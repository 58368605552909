$primary: #4b85cd;
$dark-gray2: rgb(28, 28, 30);

$dark-gray2: rgb(44, 44, 46);
$dark-gray3: rgb(58, 58, 60);
$dark-gray4: rgb(72, 72, 74) !important;
$dark-gray5: rgb(99, 99, 102);

$secondary: #1e2640;
$light-blue: #edf7ff;
$auth-bg: #f8f8ff;
$red: #ff5555;
$light-red: #fb7f7f;
$light-blue-translucent: rgba(184, 223, 255, 0.2);

$shadow-light: 0px 0px 50px rgba(41, 42, 47, 0.1);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  //'success': $success,
  //'info': $info,
  // 'warning': $warning,
  //'danger': $danger,
  //'light': $light,
  //'dark': $dark,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
