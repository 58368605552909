@import '../../assets/scss/base/responsive';
.whatifrebal-table-body {
  .rdt_TableHeadRow {
    background-color: #edf7ff;

    .rdt_TableCol {
      border: none;

      .rdt_TableCol_Sortable {
        font-size: 0.9rem;
        font-weight: 500;
        color: #373959;

        &:hover,
        &:focus {
          color: inherit;
        }
      }
    }
  }

  .rdt_TableHead + .rdt_TableBody > [id*='row-'] {
    border-bottom: 0;
    border-top: 1.5px solid #ebf0fc;

    .gJBzMw::before {
      display: none;
    }
  }
  .rdt_TableHead + .rdt_TableBody > #row-0 {
    background-color: #fafafa;

    [data-testid='expander-button-undefined'] {
      display: none;
    }

    .rdt_TableCell {
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .gJBzMw {
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      left: 1px;
      top: 50%;
      height: 1px;
      width: 10px;
      background-color: #ebf0fc;
      transform: translateY(-50%);
    }

    button {
      z-index: 1;
    }
  }

  .inrgiU {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0 0.2rem;
    border-radius: 2px;
    background: #ebf0fc;

    &:focus {
      background: #ebf0fc;
    }
  }

  .rdt_ExpanderRow {
    padding-left: 1.4rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 1.5rem;
      left: 1.4rem;
      height: 100%;
      width: 1px;
      background-color: #ebf0fc;
    }
  }
}

.whatifrebal-table-head {
  display: flex;
  margin-bottom: 0;
  min-width: 90rem;

  @include xl-screen {
    min-width: 70rem;
  }

  li {
    //  width: 13.33%;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.5rem 0.5rem;
    background-color: #edf7ff;
    text-align: right;

    @include xl-screen {
      padding: 0.5rem 0.5rem;
    }

    &:first-child {
      width: 20%;
    }
  }
}

.whatifrebal-table-body {
  min-width: 90rem;

  @include xl-screen {
    min-width: 70rem;
  }

  .whatifrebal-table-title {
    background-color: #fafafa;

    li {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  ul {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 0;
    position: relative;
    // align-items: center;
    border-top: 1.5px solid #ebf0fc;
    margin-top: 0;

    li {
      // width: 13.33%;
      font-size: 0.9rem;
      padding: 0.5rem 0.5rem;
      text-align: right;
      @include xl-screen {
        padding: 1rem 0.5rem;
      }

      &:first-child {
        width: 20%;
        display: flex;
        //align-items: center;

        .expandArrow {
          z-index: 2;
          min-width: 1.4rem;
          min-height: 1.4rem;
          display: block;
          cursor: pointer;
          position: relative;
          margin-right: 0.6rem;
          border-radius: 0.2rem;
          background-size: 0.8rem;
          background-color: #ebf0fc;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.11973 0.584872L5.16993 3.52427L2.22012 0.584872C1.92362 0.289418 1.44466 0.289418 1.14816 0.584872C0.851656 0.880327 0.851656 1.3576 1.14816 1.65305L4.63774 5.13033C4.93425 5.42578 5.41321 5.42578 5.70971 5.13033L9.1993 1.65305C9.4958 1.3576 9.4958 0.880327 9.1993 0.584872C8.90279 0.296993 8.41623 0.289418 8.11973 0.584872Z' fill='%234B85CD'/%3E%3C/svg%3E%0A");
        }
      }
    }

    & > ul {
      display: none;
      border-top: 1.5px solid #e3e3e3;
      //border-top: none;
      margin-left: auto;

      li:first-child {
        .expandArrow::before {
          content: '';
          top: 50%;
          left: -1rem;
          height: 1px;
          width: 1rem;
          position: absolute;
          background-color: #ebf0fc;
          z-index: 2;
        }
      }

      &::before {
        content: '';
        left: 1.25rem;
        width: 1px;
        height: 100%;
        z-index: 1;
        bottom: 1.7rem;
        position: absolute;
        background-color: #ebf0fc;

        @include xl-screen {
          left: 2rem;
        }
      }

      &.active::before {
        bottom: -1.6rem;
        height: calc(100% + 3.2rem);
      }

      &.active:last-child::before {
        bottom: 5.2rem;
        height: calc(100% - 4rem);
      }

      & + ul.active:last-child {
        &::before {
          display: none;
        }
      }
    }

    &.active > ul {
      display: flex;
    }

    .child {
      li:first-child {
        padding-left: 2.25rem;

        @include xl-screen {
          padding-left: 1.25rem;
        }
      }

      .sub-child {
        &::before {
          left: 2.8rem;

          @include xl-screen {
            left: 1.8rem;
          }
        }

        li {
          &:first-child {
            padding-left: 3.8rem;

            @include xl-screen {
              padding-left: 2.8rem;
            }
          }
        }

        &.sub-details-child {
          display: flex;

          &::before {
            display: none;
          }

          li {
            font-weight: 500;
            padding-top: 0rem;
            padding-bottom: 0.4rem;

            &:first-child {
              color: #888888;
              font-weight: 300;
            }
          }
        }
      }
    }

    .last-child {
      li {
        .expandArrow {
          max-width: 0.5rem;
          max-height: 0.5rem;
          min-width: 0.5rem;
          min-height: 0.5rem;
          border-radius: 100%;
          background-image: none;
          background-color: #4b85cd;
          z-index: 2;
          margin-top: 0.5rem;
        }
        text-align: right;
      }
    }

    &.active {
      & > li {
        .expandArrow {
          transform: rotateX(-180deg);
          background-color: #4b85cd;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.11973 0.584872L5.16993 3.52427L2.22012 0.584872C1.92362 0.289418 1.44466 0.289418 1.14816 0.584872C0.851656 0.880327 0.851656 1.3576 1.14816 1.65305L4.63774 5.13033C4.93425 5.42578 5.41321 5.42578 5.70971 5.13033L9.1993 1.65305C9.4958 1.3576 9.4958 0.880327 9.1993 0.584872C8.90279 0.296993 8.41623 0.289418 8.11973 0.584872Z' fill='%23fff'/%3E%3C/svg%3E%0A");
          z-index: 2;
        }
      }
    }
  }
}

.whatifrebal-table-menu {
  display: flex;
  padding: 1.6rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;

  .tableDropdown {
    border-radius: 0.2rem;
    border: 2px solid #d1deff;
    background-color: #f4f6fb;
    padding: 0.5rem 2rem 0.5rem 0.8rem;
    background-position: calc(100% - 0.5rem) center;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.07747 0.584872L4.2483 3.52427L1.41914 0.584872C1.13476 0.289418 0.67539 0.289418 0.391015 0.584872C0.106641 0.880327 0.106641 1.3576 0.391015 1.65305L3.73789 5.13033C4.02226 5.42578 4.48164 5.42578 4.76601 5.13033L8.11288 1.65305C8.39726 1.3576 8.39726 0.880327 8.11288 0.584872C7.82851 0.296993 7.36184 0.289418 7.07747 0.584872Z' fill='%23828282'/%3e%3c/svg%3e ");
  }

  .form-switch {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: auto;
    align-items: center;

    label {
      font-size: 1rem;
      margin-bottom: 0;
      user-select: none;
    }
  }

  .form-check-input {
    float: none;
    border: none;
    width: 2.6rem;
    margin-top: 0;
    height: 1.4rem;
    cursor: pointer;
    margin-left: 1rem;
    background-color: rgba(0, 0, 0, 0.1);

    &:focus {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    }

    &:checked {
      background-color: rgba(175, 212, 255, 0.4);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
    }
  }

  .dropdown-menu {
    padding: 0.6rem 0;
    left: 0 !important;
    right: auto !important;
    margin-top: 0 !important;

    li {
      margin-bottom: 0;
    }
  }
}

.up-eqity {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.4rem;
  border-radius: 100%;
  background-color: #03d618;
}

.down-eqity {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.4rem;
  border-radius: 100%;
  background-color: #f23737;
}

.whatifrebalrt-btn {
  display: flex;
  margin-left: 1.2rem;
  align-items: center;
  padding: 0.6rem 1rem;

  img {
    width: 1.2rem;
    margin-left: 0.8rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.table-head-bordered {
  thead {
    tr {
      th {
        border: 1px solid #e3e3e3;
      }
    }
  }
}
