// Extra Large Screen - 1201 to 1440 screen size
  
@mixin xl-screen {
	@media only screen and (max-width: 1440px) {
		@content;
	}
}
// Large Screen - 993 to 1170 screen size
@mixin lg-screen {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

// Medium Screen - 768 to 992 screen size
@mixin md-screen {
	@media only screen and (max-width: 992px) {
		@content;
	}
}

// Medium Screen - 575 to 767 screen size
@mixin sm-screen {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

// Medium Screen - below 575 screen size
@mixin xs-screen {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
